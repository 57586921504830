<template>
  <tr class="tr-operation">
    <td class="border-0 border-b border-solid border-gray-300 px-4 py-2">

      <label>{{operation.operation}}</label>
    </td>
    <td class="border-0 border-b border-solid border-gray-300 px-4 py-2 relative">
      <label  >{{operation.operationDate && moment()(operation.operationDate).isValid() ? moment()(operation.operationDate).format('DD MMM YYYY') : '' }}</label>
      <button v-if="operation._id" @click="removeOperation" class="rmv-operation-line bg-transparent text-red-500  border-0  rounded-cu  cursor-pointer"><i class="fas fa-trash"></i></button>
    </td>
    <td class="border-0 border-b-1 border-solid border-gray-300 px-4 py-2" style="width:35px;">

    </td>
  </tr>
</template>
<script>

import moment from "moment";
export default {
  props: ["operation", "patient"],
  // components: { Datepicker },

  data() {
    return {
      // dateLangFR: fr,
      error: false,
      errorMessage: ''
    }
  },
  methods: {
    removeOperation() {
      this.$store
        .dispatch("healthbook/REMOVE_PATIENT_ANTECEDENT_CHIRURGICAL", {
          patientId: this.patient._id,
          data:this.operation,
          onData:(data)=>{
            this.$emit('operationRemoved', this.operation)
          }
      })
    },
    moment() {
      return moment
    },


  }
};
</script>

<style lang="scss">
.rmv-operation-line  {
    display:none;
  }
.tr-operation:hover {
  .rmv-operation-line  {
    position: absolute;
    top: calc(50% - 7.6px) ;
    right:0px;
    display: inline-block;
  }
}
</style>
